'use client';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { contactEmail, siteMenu } from '@/siteNavigation';
import ContactButton from '@/components/ContactButton';
import { ButtonVariants } from '@/components/Button';
import Modal from '@/components/Modal';
import styles from './MobileMenu.module.scss';

const MobileMenu = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsMenuOpened(false);
  }, [pathname]);

  useEffect(() => {
    document.documentElement.style.overflowY = isMenuOpened ? 'hidden' : 'unset';
  }, [isMenuOpened]);

  const menuHeader = (
    <header className={styles.menuHeader}>
      <Link
        href='/'
        className={styles.logo}
      >
        <Image
          src='/media/logo.svg'
          alt='XploBiz'
          width={112}
          height={80}
        />
      </Link>
      <button
        type='button'
        className={styles.closeMenuButton}
        aria-label='menu button'
        onClick={() => setIsMenuOpened(false)}
      >
        <svg>
          <use
            xlinkHref='/media/close.svg#closeSVG'
            href='/media/close.svg#closeSVG'
          />
        </svg>
      </button>
    </header>
  );
  return (
    <>
      <button
        type='button'
        className={styles.menuButton}
        aria-label='open menu button'
        onClick={() => setIsMenuOpened(true)}
      >
        <svg>
          <use
            xlinkHref='/media/menu_closed.svg#menuClosedSVG'
            href='/media/menu_closed.svg#menuClosedSVG'
          />
        </svg>
      </button>
      {isMenuOpened && (
        <Modal
          className={styles.modal}
          closeButton={menuHeader}
        >
          <div className={classNames(styles.content, 'container')}>
            <ul
              className={styles.menu}
            >
              {siteMenu.map(item => (
                <li key={item.id}>
                  <Link
                    className={classNames(styles.menu__link, {
                      [styles.active]: pathname.includes(item.url),
                    })}
                    href={item.url}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <div className={styles.info}>
              <a
                className={styles.emailLink}
                href={`mailto:${contactEmail}`}
              >
                {contactEmail}
              </a>
              <ContactButton
                variant={ButtonVariants.SECONDARY}
              >
                contact us
              </ContactButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MobileMenu;
