type Menu = {
  id: number,
  title: string,
  url: string,
};

export const siteMenu: Menu[] = [
  {
    id: 0,
    title: 'About Us',
    url: '/about',
  },
  {
    id: 1,
    title: 'Why XploBiz',
    url: '/why_xplobiz',
  },
  {
    id: 2,
    title: 'Cases',
    url: '/cases',
  },
  {
    id: 3,
    title: 'Our solutions',
    url: '/solutions',
  },
];

export const contactEmail = 'xplode@xplobiz.ai';
export const contactTel = '+61 402 694 644';
