'use client';

import { PaletteMode, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import isContactOpen, { isThankYouOpen } from '@/store/contactModal';
import Button, { ButtonVariants } from '@/components/Button';
import Modal from '@/components/Modal';
import useDebouncedCallback from '@/useDebounceCallback';
import { API_URL } from '../../../constants';
import styles from './ContactModal.module.scss';

type FormComponentInput = {
  id: string,
  name: string,
  width?: 'half' | 'full',
  type: 'input' | 'textarea',
  inputType?: 'text' | 'email' | 'tel',
  label: string,
  value: string,
  setValue: Dispatch<SetStateAction<string>>,
  errors?: {
    required: string,
    custom?: Array<{
      condition: unknown,
      value: string,
    }>
  }
};

type RequestParams = {
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  message?: string,
};

const sendRequest = async (params: RequestParams) => {
  try {
    const response = await fetch(`${API_URL}/api/send-mail`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    return await response.json();
  } catch (e) {
    throw new Error(e);
  }
};

type Errors = {
  [key: string]: string | undefined,
};

enum SubmitStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

const theme = {
  palette: {
    mode: 'dark' as PaletteMode,
    text: {
      primary: '#E5F8FF',
    },
    primary: {
      main: '#00ADEF',
    },
    secondary: {
      main: '#FFF',
    },
    error: {
      main: '#D86B7C',
    },
  },
  typography: {
    fontFamily: 'Gontserrat, sans-serif',
  },
};
const ContactModal = () => {
  const [isContactsModalOpen, setIsContactsModalOpen] = useAtom(isContactOpen);
  const setIsThankYouModalOpen = useSetAtom(isThankYouOpen);

  const muiTheme = createTheme(theme);
  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [messageValue, setMessageValue] = useState<string>('');

  const clearForm = () => {
    setFirstNameValue('');
    setLastNameValue('');
    setEmailValue('');
    setPhoneValue('');
    setMessageValue('');
  };

  const fields: FormComponentInput[] = [
    {
      id: 'firstNameField',
      name: 'firstName',
      type: 'input',
      label: 'First Name',
      value: firstNameValue,
      setValue: setFirstNameValue,
      errors: {
        required: 'Please enter your first name',
      },
    },
    {
      id: 'lastNameField',
      name: 'lastName',
      type: 'input',
      label: 'Last Name',
      value: lastNameValue,
      setValue: setLastNameValue,
      errors: {
        required: 'Please enter your last name',
      },
    },
    {
      id: 'emailField',
      name: 'email',
      type: 'input',
      inputType: 'email',
      label: 'Email',
      value: emailValue,
      setValue: setEmailValue,
      errors: {
        required: 'Please provide your email address',
        custom: [{
          condition: emailValue !== '' && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue),
          value: 'Please enter a valid email',
        }],
      },
    },
    {
      id: 'phoneField',
      name: 'phone',
      type: 'input',
      inputType: 'tel',
      label: 'Phone',
      value: phoneValue,
      setValue: setPhoneValue,
      errors: {
        required: 'Please enter your phone',
        custom: [{
          condition: phoneValue !== '' && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(phoneValue),
          value: 'Please enter a valid phone number',
        }],
      },
    },
    {
      id: 'messageField',
      name: 'message',
      type: 'textarea',
      label: 'Message',
      value: messageValue,
      setValue: setMessageValue,
      width: 'full',
    },
  ];

  const requiredFields = fields.filter((field) => !!field?.errors?.required);

  const [isFormMutated, setIsFormMutated] = useState<boolean>(false);

  const isFormFilled = requiredFields.every(field => field.value.length > 0);
  const [errors, setErrors] = useState<Errors>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
  const [customErrorMessage, setCustomErrorMessage] = useState<null | string>(null);

  const validateForm = () => {
    setErrors({});
    let currentErrors: Errors = {};

    requiredFields.forEach(field => {
      if (field.value === '') {
        currentErrors[field.name] = field.errors?.required;
      }
      if (field.errors?.custom) {
        field.errors.custom.forEach(customError => {
          if (customError.condition) {
            currentErrors[field.name] = customError.value;
          }
        });
      }
    });
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const validateMutatedForm = useDebouncedCallback(() => {
    validateForm();
  }, 300);

  useEffect(() => {
    if (isFormMutated) {
      validateMutatedForm();
    }
  }, [fields, isFormMutated]);

  const submitForm = async () => {
    setCustomErrorMessage(null);
    setIsLoading(true);
    setSubmitStatus(null);
    try {
      const response = await sendRequest({
        first_name: firstNameValue,
        last_name: lastNameValue,
        email: emailValue,
        phone: phoneValue,
        message: messageValue,
      });
      if (response.status === 'success') {
        setSubmitStatus(SubmitStatus.SUCCESS);
        setIsFormMutated(false);
        clearForm();
        setIsThankYouModalOpen(true);
        setIsContactsModalOpen(false);
      } else if (!response.ok) {
        response.message && setCustomErrorMessage(response.message);
        setSubmitStatus(SubmitStatus.ERROR);
      }
    } catch (e) {
      console.error(e.message);
      setSubmitStatus(SubmitStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsFormMutated(true);
    const isFormValid = validateForm();

    isFormValid && submitForm();
  };

  return isContactsModalOpen && (
    <Modal
      className={styles.modal}
      closeButton={(
        <button
          type='button'
          className={styles.closeButton}
          onClick={() => setIsContactsModalOpen(false)}
        >
          <svg>
            <use
              xlinkHref='/media/close.svg#closeSVG'
              href='/media/close.svg#closeSVG'
            />
          </svg>
        </button>
      )}
    >
      <form
        className={classNames(styles.form, 'container')}
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <header>
          <h2 className={styles.title}>Contact Us</h2>
          <p className={styles.subtitle}>Fill in the contact form</p>
        </header>
        <div className={styles.main}>
          <div className={styles.formContent}>
            <ThemeProvider theme={muiTheme}>
              {fields.map(field => (
                <TextField
                  key={field.id}
                  value={field.value}
                  onChange={e => field.setValue(e.target.value)}
                  error={!!errors[field.name]}
                  helperText={!!errors[field.name] && errors[field.name]}
                  className={classNames(styles.input, {
                    [styles.fullWidth]: field.width === 'full',
                    [styles.textarea]: field.type === 'textarea',
                  })}
                  id={field.id}
                  label={field.label}
                  type={field.inputType}
                  variant='standard'
                  required={!!field.errors?.required || false}
                  multiline={field.type === 'textarea'}
                  minRows={field.type === 'textarea' ? 1 : undefined}
                  fullWidth
                />
              ))}
            </ThemeProvider>
          </div>
        </div>
        <footer>
          <Button
            variant={ButtonVariants.PRIMARY}
            type='submit'
            disabled={!isFormFilled || (isFormMutated && Object.keys(errors).length > 0)}
            icon={isLoading && (
              <svg className={styles.spinner}>
                <use
                  xlinkHref='/media/spinner.svg#spinnerSVG'
                  href='/media/spinner.svg#spinnerSVG'
                />
              </svg>
            )}
            iconSize={{ width: 16, height: 16 }}
          >
            Xplode My Business NOW
          </Button>
          <p className={classNames(styles.terms, {
            [styles.error]: submitStatus === SubmitStatus.ERROR,
          })}
          >
            {submitStatus === SubmitStatus.ERROR
              ? (customErrorMessage || ('Form submission error. Try again'))
              : ' By submitting the form you consent to the use of your personal data in agreement with the privacy policy'}
          </p>
        </footer>
      </form>
    </Modal>
  );
};

export default ContactModal;
